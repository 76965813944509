import React from 'react'
import { format } from 'date-fns'
// import PortableText from '../components/portableText'
import { FiExternalLink } from 'react-icons/fi'

function ArticleList (props) {
  const info = props.article

  return (
    <div className=''>
      <div className=''>
        <div>
          {/* <p className='text-sm leading-5 text-gray-500'>
            <time dateTime='2020-03-16'>{format(info.publishedAt, 'MMMM Do, YYYY')}</time>
          </p> */}
          <h3 className='mt-2 text-xl leading-7 font-semibold text-indigo-600'>
            <a href={info.url} className='flex items-center' target='_blank'>
              {info.title} <FiExternalLink className='pl-1' />
            </a>
          </h3>
          {/* <div className='mt-3 text-base leading-6 text-gray-500'>
            <PortableText blocks={info._rawExcerpt} />
          </div> */}
        </div>
      </div>
    </div>
  )
}

ArticleList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ArticleList
