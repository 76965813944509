import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
// import SEO from '../components/seo'
import Layout from '../containers/layout'
import ArticleList from '../components/article-list'
import { FiExternalLink } from 'react-icons/fi'
// import '../styles/content.css'
import PortableText from '../components/portableText'
import TitleAndDescription from '../components/TitleAndDescription'

export const query = graphql`
  query articlePageQuery {
    article: sanityProject {
      title
      projects {
        title
        description
        url
      }
      _rawExcerpt(resolveReferences: {maxDepth: 10})
    }
  }
`

const ArticlePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const { title, projects } = data.article

  // const site = (data || {}).site
  const postNodes = (data || {}).post

  // if (!site) {
  //   throw new Error(
  //     'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
  //   )
  // }

  return (
    <Layout>
      <Container>
        <TitleAndDescription title={title} excerpt={data.article._rawExcerpt} />
        <div>
          {data.article.projects.map((item) => {
            return (
              <div className='mb-8'>
                <h3 className='mt-0 text-xl leading-7 font-semibold text-gray-900 hover:text-gray-700'>
                  <a href={item.url} className='flex items-center' target='_blank'>
                    {item.title} <FiExternalLink className='pl-1' />
                  </a>
                </h3>
                <div className='mt-1 text-base leading-6 text-gray-700'>
                  {item.description}
                </div>
              </div>
            )
          })}
        </div>
        {/* )} */}
      </Container>
    </Layout>
  )
}

export default ArticlePage

//  postsallSanityArticl{
//     edges
//       no {
//         id //         title

//         _rawExcerpt(resolveReference { maxDepth10 })
//   /
// }
//     }
//   }
